<template>
  <div v-loading="formLoading">
    <div class="card mb-5 mb-xl-10" v-if="roleID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldRoleData.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form ref="formRef" :model="formData" :rules="rules" class="w-100 demo-ruleForm" status-icon>
          <div class="row g-9">
            <div class="col-md-12 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">Role Name</label>
                <el-form-item prop="role">
                  <el-input size="large" v-model="formData.role" type="text" placeholder="Name"/>
                </el-form-item>
              </div>
            </div>
          </div>
          <label class="required fs-6 fw-bold mb-2">Assign Permission</label>
          <el-form-item prop="permissions">
            <el-table ref="multipleTableRef" :data="tableData" border style="width: 150%">
              <el-table-column label="#" width="55">
                <template #header>
                  <el-checkbox v-model="checkAll" :indeterminate="isIndeterminateMain" @change="checkAllCheckBoxes"></el-checkbox>
                </template>
                <template #default="scope">
                  <el-checkbox v-model="scope.row.is_checked_all" :indeterminate="scope.row.is_indeterminate" :value="scope.row.is_checked_all" @change.native="handleCheckAllChange($event, scope.$index)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="Section"  prop="module" width="300" /> 
              <el-table-column label="Permission"  >
                <template #default="scope" >
                  <el-checkbox-group v-model="checkedPermissions" v-on:change="handleCheckedPermissionsChange( $event, scope.$index )">
                    <el-checkbox v-for="(permission, index) in scope.row.permissions" :key="index" :label="permission">{{ scope.row.permissionskeys[index] }}</el-checkbox>
                  </el-checkbox-group>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item> 
          <div class="row g-9">
            <div class="col-md-12 mb-5 mt-12">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
                <button type="button" class=" btn btn-primary me-2"  @click="submitForm(formRef, 'save_back')">{{constants.general.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'roles'})">{{constants.general.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { roleList } from "@/composable/roles";
import globalConstant from "@/core/data/globalConstant.js";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import useEventBus from '../../composable/useEventBus';
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { formatText } from "@/core/helpers/common";

export default {
  name: "add-new-customer",
  inheritAttrs: false,
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const route = useRoute();
    const router = useRouter();
    const checkAll = ref(false);
    const totalPermissions = ref(0);
    const is_indeterminate = ref(false);
    const isIndeterminateMain = ref(false);
    const checkedPermissions = ref(['readDashboard','updateCustomer']);
    const roleID = ref(0);
    const formLoading = ref(false);
    const constants = globalConstant;
    const oldRoleData = reactive({
      name: ''
    });
    const formData = reactive({
      role: "",
      permissions: "",
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName == "save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push({ name: 'roles'});
      }
    })
    let allRoles = roleList();
    const tableData = reactive(allRoles);
    tableData[0].is_checked_all = true
    tableData[18].is_checked_all = true
    const handleCheckAllChange = (event, index) => {
      tableData[index].permissions.forEach((element, index) => {
        if (event) {
          checkedPermissions.value.includes(element) == false ? checkedPermissions.value.push(element) : "";
        } else {
          checkedPermissions.value.splice(checkedPermissions.value.indexOf(element),1);
        }
      });
      event ? (tableData[index].is_checked_all = true) : false;
      tableData[index].is_indeterminate = false;
      checkedPermissions.value.length == 0 ? (isIndeterminateMain.value = false) : (isIndeterminateMain.value = true);
    
      if (checkedPermissions.value.length == totalPermissions.value) {
        checkAll.value = true;
        isIndeterminateMain.value = false;
      } else {
        checkAll.value = false;
      }
      formData.permissions = checkedPermissions.value.join(",");      
    };

    const handleCheckedPermissionsChange = (value, index) => {
      if (value.length > 0) {
        isIndeterminateMain.value = true;
      } else {
        isIndeterminateMain.value = false;
        checkAll.value = false;
      }

      let selectedPermissoins = value;
      let permissionRow = tableData[index].permissions;
      permissionRow = permissionRow.filter((val) =>
        selectedPermissoins.includes(val)
      );

      let indeterminateModule = false;
      let checkedModule = false;

      if (tableData[index].permissions.length == permissionRow.length) {
        checkedModule = true;
      } else if (permissionRow.length > 0) {
        indeterminateModule = true;
      }

      tableData[index].is_checked_all = checkedModule;
      tableData[index].is_indeterminate = indeterminateModule;

      var res = tableData.reduce(function (obj, v) {
        obj[v.is_checked_all] = (obj[v.is_checked_all] || 0) + 1;
        return obj;
      }, {});

      if (res.true !== undefined && res.true == tableData.length) {
        checkAll.value = true;
        isIndeterminateMain.value = false;
      }
      formData.permissions = checkedPermissions.value.join(",");
    };

    const checkAllCheckBoxes = (value) => {
      checkedPermissions.value = [];
      tableData.forEach((element) => {
        if (value) {
          element.is_checked_all = true;
          element.is_indeterminate = false;
          element.permissions.forEach((element) => {
            checkedPermissions.value.push(element);
          });
        } else {
          element.is_checked_all = false;
          element.is_indeterminate = false;
          checkedPermissions.value = [];
        }
      });
      value == true ? (checkAll.value = true) : "";
      isIndeterminateMain.value = false;

      formData.permissions = checkedPermissions.value.join(",");
    };
    const validatePermissions = (rule, value, callback) => {
      setTimeout(function(){
        if (checkedPermissions.value.length <= 0) {
          callback(new Error("Please select at least one role"));
        } else {
          callback();
        }
      }, 50);
      
    };
    const rules = ref({
      role: [ { required: true, message: "Role name is required", trigger: "change" } ],
      permissions: [ { type: "array", required: true, validator: validatePermissions, trigger: "change" } ],
    });
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction = false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
          let requestUrl = "roles";
          if (roleID.value > 0) {
            requestUrl = "roles/" + roleID.value;
            formData._method = "PUT";
          }
          ApiService.post(requestUrl, formData)
            .then((response) => {
              if (response.data) {
                let status = response.status;
                let message = response.data.message;

                if (status == 200 || status == 201) {
                  oldRoleData.name = formData.role;
                  if (roleID.value == 0 || roleID.value === undefined)
                    roleID.value = response.data.data.id;

                  notificationFire(message, "success");
                  if (btnName == "save") {
                    (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save") ? (loading.value = false) : (loadingback.value = false);
                    if (typeof route.params.id === "undefined") {
                      setCurrentPageBreadcrumbs(formatText('edit-role'), [{"name": "Roles", "path": "roles"}, { name: formatText('edit-role'), active: true }]);
                      router.push({ name: 'edit-role', params: { id: roleID.value } })
                    }
                  } else if (btnName == "save_back") {
                    router.push({ name: 'roles'})
                  }
                }
              }
            })
            .catch((error) => {
              let response = error.response;
              notificationFire(response.data.message, "error");
              (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save") ? (loading.value = false) : (loadingback.value = false);
            });
        }
      });
    };

    const getRoleData = async () => {
      formLoading.value = true;
      await ApiService.query("roles/" + roleID.value)
        .then(({ data }) => {
          if (data.status) {
            formData.role = oldRoleData.name = data.data.name;
            checkedPermissions.value = data.data.permissions.map(
              ({ name }) => name
            );
            tableData.forEach((element, index) => {
              handleCheckedPermissionsChange(checkedPermissions.value, index);
            });
          } else {
            notificationFire("Something went wrong", "error");
            loading.value = false;
            loadingback.value = false;
          }
          formLoading.value = false;
        })
        .catch((error) => {
          formLoading.value = false;
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
            if (status == 404) {
              router.push({ name: 'roles'})
            }
          }
          loading.value = false;
          loadingback.value = false;
        });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :constants.general.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader':false, 'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed)}, {"name": constants.general.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": constants.general.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }

    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Roles", "path": "roles"}, { name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createRole") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateRole") || role == "Super Admin")
        permission.isEditAllowed = true;

      tableData.forEach((element) => {
        element.permissions.forEach((element) => {
          totalPermissions.value++;
        });
      });
      if (route.params.id != "undefined") {
        roleID.value = route.params.id;
      }

      if (roleID.value > 0) {
        await getRoleData();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    return {
      tableData,
      checkedPermissions,
      checkAll,
      handleCheckAllChange,
      is_indeterminate,
      handleCheckedPermissionsChange,
      isIndeterminateMain,
      checkAllCheckBoxes,
      loading,
      loadingback,
      formRef,
      submitForm,
      formData,
      roleID,
      rules,
      formLoading,
      constants,
      permission,
      oldRoleData
    };
  },
};
</script>