//We need to add new element here for new roles with specefic permissions.
export function roleList() {
  return [
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Dashboard",
      permissionskeys: ["Read"],
      permissions: ["readDashboard"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Orders",
      permissionskeys: ["Create", "Read", "Pull", "View Details", "View Products", "Import Order", "Import Order History", "Read Order History","Download Order Invoice", "Order Watch List"],
      permissions: ["createOrder", "readOrder", "pullOrder", "viewOrder", "viewOrderProduct", "importOrder", "importOrderHistory", "readOrderHistory", "downloadOrderInvoice","orderWatchlist"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Order Products",
      permissionskeys: ["Read", "View Details", "Download Shipment Label", "Download Packaging Slip","Download Job Ticket", "Modify Shipment Label", "Update Order Product Status"],
      permissions: ["readOrderProduct", "viewOrderProductDetails", "downloadShipmentLabel", "downloadpackagingslip","downloadTicketOrder","modifyShipmentLabel","updateOrderProductStatus"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Pending Order",
      permissionskeys: [ "Read", "View Details", "View Order Products"],
      permissions: ["readPendingOrder", "viewPendingOrder", "viewPendingOrderProduct"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Pending Order Products",
      permissionskeys: ["Read", "View Details"],
      permissions: ["readPendingOrderProduct", "viewPendingOrderProductDetails"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Job Board",
      permissionskeys: ["Read", "Update"],
      permissions: ["readJobBoard", "updateJobBoard"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Trading Partner",
      permissionskeys: ["Create", "Read", "Update", "Delete", "Product Excel Mapping", "Order Excel Mapping","Trading Partner SKU", "Trading Partner Mapped SKU", "Third party billing shipping"],
      permissions: ["createTradingPartner", "readTradingPartner", "updateTradingPartner", "deleteTradingPartner", "productExcelMapping", "orderExcelMapping", 'tradingPartnerSku', 'tradingPartnerMappedSku', 'thirdPartyBillingShipping']
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Product",
      permissionskeys: ["Create", "Read", "Update", "Delete", "View", "Duplicate", "Import", "Import History","Generate SKU", "Assign Option", "Delete Product Import History"],
      permissions: ["createProduct", "readProduct", "updateProduct", "deleteProduct", "viewProduct", "duplicateProduct", "importProduct", "importProductHistory", "generateSKUProduct", "assignOptionProduct", "deleteProductImportHistory"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Product Option",
      permissionskeys: ["Create", "Read", "Update", "Delete", "View", "Duplicate"],
      permissions: ["createProductMasterOption", "readProductMasterOption", "updateProductMasterOption", "deleteProductMasterOption", "viewProductOption", "duplicateProductOption"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Product SKU",
      permissionskeys: ["Read", "Update", "Export", "Update Shipping Package"],
      permissions: ["readProductSKU", "updateProductSKU", "exportProductSKU", "updateShippingPackage"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Product Weight",
      permissionskeys: ["Create", "Read", "Import","Import History"],
      permissions: ["createProductWeight", "readProductWeight", "importProductWeight","importProductWeightHistory"]
    },
  
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Template Management",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createTemplate", "readTemplate", "updateTemplate", "deleteTemplate"]
    },

    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Master Packages",
      permissionskeys: ["Create", "Read", "Update", "Delete", "Duplicate"],
      permissions: ["createMasterPackage", "readMasterPackage", "updateMasterPackage", "deleteMasterPackage", "duplicateMasterPackage"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Shipping Packages",
      permissionskeys: ["Read", "Update", "Import", "Import Package History"],
      permissions: ["readShippingPackage", "updateShippingPackage","importShippingPackage", "importShippingPackageHistory"]
    },

    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Reports",
      permissionskeys: ["View Order Summary Report", "Export Order Summary Report Data","View Order Detail Report","View Product Sales Report","Export Product Sales Report Data","View Job Progress Report","Export Job Progess Report Data"],
      permissions: ["ViewOrderSummaryReport", "ExportOrderSummeryReportData","ViewOrderDetailReport","ViewProductSalesReport","ExportProductSalesReportData","ViewJobProgressReport","ExportJobProgressReportData"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Partner Sku",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createPartnerSku", "readPartnerSku", "updatePartnerSku", "deletePartnerSku"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Press",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createPress", "readPress", "updatePress", "deletePress"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Ink",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createInk", "readInk", "updateInk", "deleteInk"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Customer",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createCustomer", "readCustomer", "updateCustomer", "deleteCustomer"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Roles Management",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createRole", "readRole", "updateRole", "deleteRole"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Order Product Status",
      permissionskeys: ["Create", "Read", "Update", "Delete"],
      permissions: ["createOrderProductStatus", "readOrderProductStatus", "changeOrderProductStatus", "deleteOrderProductStatus"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Art SKU",
      permissionskeys: ["Create", "Read", "Update", "Delete", "Import", "Export", "History"],
      permissions: ["createArtSku", "readArtSku", "updateArtSku", "deleteArtSku", "importArtSku", "exportArtSku", "importArtSkuHistory"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Configuraion Setting",
      permissionskeys: ["Read", "Update"],
      permissions: ["readConfigurationSetting", "updateConfigurationSetting"]
    },
    {
      is_checked_all: false,
      is_indeterminate: false,
      module: "Shipping Method",
      permissionskeys: ["Read", "Update"],
      permissions: ["readShippingMethod", "updateShippingMethod"]
    },
  ];
}


